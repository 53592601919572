$(function () {
    // Style header navbar dropdown
    $(".custom-dropdown").on("show.bs.dropdown", function () {
        let that = $(this);
        setTimeout(function () {
            that.find(".dropdown-menu").addClass("active");
        }, 100);
    });
    $(".custom-dropdown").on("hide.bs.dropdown", function () {
        $(this).find(".dropdown-menu").removeClass("active");
    });

    if ($(window).width() <= 576) {
        $(".sub-category-item").click(function (e) {
            let innerSubCategory = $(this).find(".inner-sub-category");
            $(innerSubCategory).toggleClass("sub-menu-show");
            $(".inner-sub-category")
                .not($(this).find(".inner-sub-category"))
                .removeClass("sub-menu-show");
            e.stopPropagation();
        });

        $(document).click(function (e) {
            if (
                e.target == $(".cat-button")[0] ||
                e.target == $(".lni-menu")[0]
            ) {
            } else {
                $(".sub-category").removeClass("sub-category-show");
                $(".inner-sub-category").removeClass("sub-menu-show");
                $(".arrow-icon-left").find("i").removeClass("lni-chevron-up");
                $(".arrow-icon-right").find("i").removeClass("lni-chevron-up");
            }
        });

        // Show sub category
        $(".mega-category-menu").click(function (e) {
            $(".sub-category").toggleClass("sub-category-show");
        });

        // Change arrow icon for sub category item
        if ($(".arrow-icon-left")) {
            $(".arrow-icon-left > i").removeClass("lni-chevron-right");
            $(".arrow-icon-left > i").addClass("lni-chevron-down");
        }
        if ($(".arrow-icon-right")) {
            $(".arrow-icon-right > i").removeClass("lni-chevron-left");
            $(".arrow-icon-right > i").addClass("lni-chevron-down");
        }

        // Show inner sub category
        $(".sub-category-item").click(function (e) {
            if ($(".arrow-icon-left")) {
                $(this).find("i").toggleClass("lni-chevron-up");
            } else {
                $(this).find("i").toggleClass("lni-chevron-up");
            }
            e.stopPropagation();
        });
    } else {
        // Change arrow icon for sub category item
        if ($(".arrow-icon-left")) {
            $(".arrow-icon-left > i").removeClass("lni-chevron-down");
            $(".arrow-icon-left > i").addClass("lni-chevron-right");
        }
        if ($(".arrow-icon-right")) {
            $(".arrow-icon-right > i").removeClass("lni-chevron-down");
            $(".arrow-icon-right > i").addClass("lni-chevron-left");
        }
    }
    //     $(".sub-category-item").click(function (e) {
    //         let innerSubCategory = $(this).find(".inner-sub-category");
    //         $(innerSubCategory).toggleClass("sub-menu-show");
    //         $(".inner-sub-category")
    //             .not($(this).find(".inner-sub-category"))
    //             .removeClass("sub-menu-show");
    //         e.stopPropagation();
    //     });

    //     $(document).click(function (e) {
    //         if (
    //             e.target == $(".cat-button")[0] ||
    //             e.target == $(".lni-menu")[0]
    //         ) {
    //         } else {
    //             $(".sub-category").removeClass("sub-category-show");
    //             $(".inner-sub-category").removeClass("sub-menu-show");
    //             $(".arrow-icon-left").find("i").removeClass("lni-chevron-up");
    //             $(".arrow-icon-right").find("i").removeClass("lni-chevron-up");
    //         }
    //     });

    //     // Show sub category
    //     $(".mega-category-menu").click(function (e) {
    //         $(".sub-category").toggleClass("sub-category-show");
    //     });

    //     // Change arrow icon for sub category item
    //     if ($(".arrow-icon-left")) {
    //         $(".arrow-icon-left > i").removeClass("lni-chevron-right");
    //         $(".arrow-icon-left > i").addClass("lni-chevron-down");
    //     }
    //     if ($(".arrow-icon-right")) {
    //         $(".arrow-icon-right > i").removeClass("lni-chevron-left");
    //         $(".arrow-icon-right > i").addClass("lni-chevron-down");
    //     }

    //     // Show inner sub category
    //     $(".sub-category-item").click(function (e) {
    //         if ($(".arrow-icon-left")) {
    //             $(this).find("i").toggleClass("lni-chevron-up");
    //         } else {
    //             $(this).find("i").toggleClass("lni-chevron-up");
    //         }
    //         e.stopPropagation();
    //     });
    // }
});

$(window).resize(function () {
    if ($(window).width() <= 576) {
        $(".sub-category-item").click(function (e) {
            let innerSubCategory = $(this).find(".inner-sub-category");
            $(innerSubCategory).toggleClass("sub-menu-show");
            $(".inner-sub-category")
                .not($(this).find(".inner-sub-category"))
                .removeClass("sub-menu-show");
            e.stopPropagation();
        });

        $(document).click(function (e) {
            if (
                e.target == $(".cat-button")[0] ||
                e.target == $(".lni-menu")[0]
            ) {
            } else {
                $(".sub-category").removeClass("sub-category-show");
                $(".inner-sub-category").removeClass("sub-menu-show");
                $(".arrow-icon-left").find("i").removeClass("lni-chevron-up");
                $(".arrow-icon-right").find("i").removeClass("lni-chevron-up");
            }
        });

        // Show sub category
        $(".mega-category-menu").click(function (e) {
            $(".sub-category").toggleClass("sub-category-show");
        });

        // Change arrow icon for sub category item
        if ($(".arrow-icon-left")) {
            $(".arrow-icon-left > i").removeClass("lni-chevron-right");
            $(".arrow-icon-left > i").addClass("lni-chevron-down");
        }
        if ($(".arrow-icon-right")) {
            $(".arrow-icon-right > i").removeClass("lni-chevron-left");
            $(".arrow-icon-right > i").addClass("lni-chevron-down");
        }

        // Show inner sub category
        $(".sub-category-item").click(function (e) {
            if ($(".arrow-icon-left")) {
                $(this).find("i").toggleClass("lni-chevron-up");
            } else {
                $(this).find("i").toggleClass("lni-chevron-up");
            }
            e.stopPropagation();
        });
    } else {
        // Change arrow icon for sub category item
        if ($(".arrow-icon-left")) {
            $(".arrow-icon-left > i").removeClass("lni-chevron-down");
            $(".arrow-icon-left > i").addClass("lni-chevron-right");
        }
        if ($(".arrow-icon-right")) {
            $(".arrow-icon-right > i").removeClass("lni-chevron-down");
            $(".arrow-icon-right > i").addClass("lni-chevron-left");
        }
    }
});
